const bankAccounts = {
  unlinkAccount: {
    error: null,
    loading: false,
    success: false,
    data: {},
  },
  linkBankAccount: {
    error: null,
    loading: false,
    success: false,
    data: {},
  },
  linkBankAccountRequest: {
    error: null,
    loading: false,
    success: false,
    data: {},
  },
  bankList: {
    error: null,
    loading: false,
    success: false,
    data: [],
  },
};
export default bankAccounts;
