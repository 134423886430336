import {
  Row,
  Col,
  List,
  Card,
  Space,
  Empty,
  Avatar,
  Button,
  Skeleton,
  Typography,
  Tag,
} from "antd";
import moment from "moment";
import { useIntl } from "react-intl";
import { RefetchOptions, UseQueryResult } from "@tanstack/react-query";
import { EnvironmentOutlined } from "@ant-design/icons";

import countries from "utils/countries";
import getInitials from "utils/nameInitials";
import { useTransactionTypes } from "./utils";
import TransactionInterface from "types/finances/Transaction";
import { useCancelSendCash } from "queries/useFetchTransactions";
import getStatusColor from "utils/getStatusColor";
import PopConfirmButton from "components/PopConfirmButton";
import NotificationTrigger from "components/Alert/Notification";

const { Text, Title, Link } = Typography;

interface Props {
  size?: "small" | "default";
  isLoading: boolean;
  onClose?: () => void;
  transaction?: TransactionInterface;
  handleButtonClick: () => void;
  isCardVisible: boolean;
  refetchTransactions: (options?: RefetchOptions) => Promise<UseQueryResult>;
}

const TransactionsDetails = ({
  isLoading,
  transaction,
  isCardVisible,
  handleButtonClick,
  size = "default",
  onClose,
  refetchTransactions,
}: Props) => {
  const intl = useIntl();
  const { transactionTypes } = useTransactionTypes();
  const { mutate: cancelSendCash, isLoading: isCancelCashLoading } =
    useCancelSendCash({
      onSuccess: refetchTransactions,
      onError: (err: any) => {
        NotificationTrigger.error({
          message: "Error",
          description: err?.response?.data?.[0]?.Description || err?.message,
        });
      },
    });

  return (
    <>
      {isCardVisible && (
        <Card bordered={true}>
          <Row gutter={[0, 16]}>
            <Col span={16}>
              <Title level={5} style={{ marginBottom: 0, marginTop: -5 }}>
                {intl.formatMessage({
                  id: "dd.new",
                  defaultMessage: "Transaction details",
                })}
              </Title>
            </Col>
            <Col span={8}>
              <Button
                onClick={handleButtonClick}
                style={{ marginBottom: 0, marginTop: -8, float: "right" }}
                type="primary"
              >
                Close
              </Button>
            </Col>

            {transaction || isLoading ? (
              <Col span={24}>
                <List itemLayout="horizontal" loading={isLoading}>
                  <List.Item>
                    <Row
                      wrap={false}
                      align="middle"
                      gutter={[16, 0]}
                      className="w-100"
                    >
                      <Col span={12}>
                        <Text type="secondary">
                          {intl.formatMessage({
                            id: "dd.new",
                            defaultMessage: "Date",
                          })}
                          {":"}
                        </Text>
                      </Col>

                      <Col span={12}>
                        <Skeleton
                          active
                          paragraph={false}
                          loading={isLoading}
                          title={{ style: { margin: 0 } }}
                        >
                          <Text>
                            {moment(
                              transaction?.Date.replace(/\s-\s/, " ")
                            ).format("DD MMM YYYY, HH:mm")}
                          </Text>
                        </Skeleton>
                      </Col>
                    </Row>
                  </List.Item>

                  <List.Item>
                    <Row
                      wrap={false}
                      gutter={[16, 0]}
                      className="w-100"
                      align="middle"
                    >
                      <Col span={12}>
                        <Text type="secondary">
                          {intl.formatMessage({
                            id: "dd.new",
                            defaultMessage: "Amount",
                          })}
                          {":"}
                        </Text>
                      </Col>

                      <Col span={12}>
                        <Skeleton
                          active
                          paragraph={false}
                          loading={isLoading}
                          title={{ style: { margin: 0 } }}
                        >
                          <Text>
                            <Space>
                              {transaction?.OpsType}
                              {transaction?.Currency}
                              {transaction?.Amount}
                            </Space>
                          </Text>
                        </Skeleton>
                      </Col>
                    </Row>
                  </List.Item>

                  <List.Item>
                    <Row
                      wrap={false}
                      align="middle"
                      gutter={[16, 0]}
                      className="w-100"
                    >
                      <Col span={12}>
                        <Text type="secondary">
                          {intl.formatMessage({
                            id: "dd.new",
                            defaultMessage: "Transaction Number",
                          })}
                          {":"}
                        </Text>
                      </Col>

                      <Col span={12}>
                        <Skeleton
                          active
                          paragraph={false}
                          loading={isLoading}
                          title={{ style: { margin: 0 } }}
                        >
                          <Text>
                            {"#"}
                            {transaction?.TransactionNumber}
                          </Text>
                        </Skeleton>
                      </Col>
                    </Row>
                  </List.Item>

                  <List.Item>
                    <Row
                      wrap={false}
                      align="middle"
                      gutter={[16, 0]}
                      className="w-100"
                    >
                      <Col span={12}>
                        <Text type="secondary">
                          {intl.formatMessage({
                            id: "dd.new",
                            defaultMessage: "Status",
                          })}
                          {":"}
                        </Text>
                      </Col>

                      <Col span={12}>
                        <Skeleton
                          active
                          paragraph={false}
                          loading={isLoading}
                          title={{ style: { margin: 0 } }}
                        >
                          <Tag
                            color={getStatusColor(transaction?.TransStatus)}
                            className="ant-tag-modified"
                          >
                            {transaction?.TransStatusText}
                          </Tag>
                        </Skeleton>
                      </Col>
                    </Row>
                  </List.Item>

                  {String(transaction?.TransactionType).toUpperCase() ===
                    "CS" && transaction?.TransStatus === "0" ? (
                    <List.Item>
                      <Row
                        wrap={false}
                        align="middle"
                        gutter={[16, 0]}
                        className="w-100"
                      >
                        <PopConfirmButton
                          danger
                          type="primary"
                          disabled={isLoading || isCancelCashLoading}
                          loading={isLoading || isCancelCashLoading}
                          onConfirm={(otp: string) => {
                            try {
                              cancelSendCash({
                                PIN: otp,
                                TransactionID: transaction?.TransactionID,
                                UserPID: transaction?.SourcePID,
                              });
                            } catch (error) {}
                          }}
                          title={intl.formatMessage({
                            id: "dd.new",
                            defaultMessage:
                              "Provide your OTP / PIN to cancel this send cash transaction",
                          })}
                          label={intl.formatMessage({
                            id: "dd.new",
                            defaultMessage: "Cancel",
                          })}
                        />
                      </Row>
                    </List.Item>
                  ) : null}

                  <List.Item>
                    <Space direction="vertical">
                      <Text type="secondary" className="text-small">
                        {intl.formatMessage({
                          id: "dd.new",
                          defaultMessage: "Origin",
                        })}
                      </Text>
                      <Skeleton
                        active
                        avatar
                        loading={isLoading}
                        paragraph={{ width: 196 }}
                      >
                        <Space size={16} align="start">
                          <Avatar
                            size="large"
                            alt="profile"
                            src={transaction?.SenderPictureURL}
                          >
                            {getInitials(
                              transaction?.SenderFirstName,
                              transaction?.SenderLastName
                            )}
                          </Avatar>
                          <Space direction="vertical" size={0}>
                            <Text copyable>{transaction?.WalletNumber}</Text>
                            <Text>{`${transaction?.SenderFirstName} ${transaction?.SenderLastName}`}</Text>
                            <Text type="secondary">
                              {transaction?.SenderEmail}
                            </Text>
                            <Space className="text-small">
                              {transaction?.SenderCountryCode ? (
                                <Text type="secondary">
                                  <Space size={4}>
                                    <EnvironmentOutlined />
                                    {
                                      countries?.find(
                                        (c) =>
                                          c.countryCode ===
                                          transaction?.SenderCountryCode
                                      )?.countryName
                                    }
                                  </Space>
                                </Text>
                              ) : null}
                              {transaction?.SenderPhone ? (
                                <>
                                  <Text type="secondary">{"|"}</Text>
                                  <Link
                                    href={`call:+${transaction?.SenderPhone}`}
                                  >
                                    {transaction?.SenderPhone}
                                  </Link>
                                </>
                              ) : null}
                            </Space>
                          </Space>
                        </Space>
                      </Skeleton>
                    </Space>
                  </List.Item>

                  <List.Item>
                    <Space direction="vertical">
                      <Text type="secondary" className="text-small">
                        {intl.formatMessage({
                          id: "dd.new",
                          defaultMessage: "Target",
                        })}
                      </Text>
                      <Skeleton
                        active
                        avatar
                        loading={isLoading}
                        paragraph={{ width: 196 }}
                      >
                        <Space size={16} align="start">
                          <Avatar
                            size="large"
                            alt="profile"
                            src={transaction?.ContactPictureURL}
                          >
                            {getInitials(
                              transaction?.ContactFirstName,
                              transaction?.ContactLastName
                            )}
                          </Avatar>
                          <Space direction="vertical" size={0}>
                            <Text copyable>{transaction?.TargetAccount}</Text>
                            <Text>{`${transaction?.ContactFirstName} ${transaction?.ContactLastName}`}</Text>
                            <Text type="secondary">
                              {transaction?.ContactEmail}
                            </Text>
                            <Space className="text-small">
                              {transaction?.ContactCountryCode ||
                              transaction?.SenderCountryCode ? (
                                <>
                                  <Text type="secondary">
                                    <Space size={4}>
                                      <EnvironmentOutlined />
                                      {
                                        countries?.find(
                                          (c) =>
                                            c.countryCode ===
                                            (transaction?.ContactCountryCode ||
                                              transaction?.SenderCountryCode)
                                        )?.countryName
                                      }
                                    </Space>
                                  </Text>
                                </>
                              ) : null}
                              {transaction?.ContactPhone ? (
                                <>
                                  <Text type="secondary">{"|"}</Text>
                                  <Link
                                    href={`call:+${transaction?.ContactPhone}`}
                                  >
                                    {transaction?.ContactPhone}
                                  </Link>
                                </>
                              ) : null}
                            </Space>
                          </Space>
                        </Space>
                      </Skeleton>
                    </Space>
                  </List.Item>
                  <List.Item>
                    <Row
                      wrap={false}
                      align="middle"
                      gutter={[16, 0]}
                      className="w-100"
                    >
                      <Col span={12}>
                        <Text type="secondary">
                          {intl.formatMessage({
                            id: "dd.new",
                            defaultMessage: "Previous balance",
                          })}
                          {":"}
                        </Text>
                      </Col>

                      <Col span={12}>
                        <Skeleton
                          active
                          paragraph={false}
                          loading={isLoading}
                          title={{ style: { margin: 0 } }}
                        >
                          <Text>
                            {transaction?.PreviousBalance}{" "}
                            {transaction?.Currency}
                          </Text>
                        </Skeleton>
                      </Col>
                    </Row>
                  </List.Item>
                  <List.Item>
                    <Row
                      wrap={false}
                      align="middle"
                      gutter={[16, 0]}
                      className="w-100"
                    >
                      <Col span={12}>
                        <Text type="secondary">
                          {intl.formatMessage({
                            id: "dd.new",
                            defaultMessage: "New balance",
                          })}
                          {":"}
                        </Text>
                      </Col>

                      <Col span={12}>
                        <Skeleton
                          active
                          paragraph={false}
                          loading={isLoading}
                          title={{ style: { margin: 0 } }}
                        >
                          <Text>
                            {transaction?.NewBalance} {transaction?.Currency}
                          </Text>
                        </Skeleton>
                      </Col>
                    </Row>
                  </List.Item>
                  <List.Item>
                    <Space size={0} direction="vertical">
                      <Text type="secondary">
                        {intl.formatMessage({
                          id: "119.new",
                          defaultMessage: "Description",
                        })}
                      </Text>
                      <Skeleton
                        active
                        paragraph={false}
                        loading={isLoading}
                        title={{ width: 196 }}
                      >
                        <Text>{transaction?.Description || "-"}</Text>
                      </Skeleton>
                    </Space>
                  </List.Item>

                  <List.Item>
                    <Space size={0} direction="vertical">
                      <Text type="secondary">
                        {intl.formatMessage({
                          id: "119.new",
                          defaultMessage: "Reference",
                        })}
                      </Text>
                      <Skeleton
                        active
                        paragraph={false}
                        loading={isLoading}
                        title={{ width: 196 }}
                      >
                        <Text>{transaction?.Reference || "-"}</Text>
                      </Skeleton>
                    </Space>
                  </List.Item>

                  <List.Item>
                    <Row
                      wrap={false}
                      align="middle"
                      gutter={[16, 0]}
                      className="w-100"
                    >
                      <Col span={12}>
                        <Text type="secondary">
                          {intl.formatMessage({
                            id: "117",
                            defaultMessage: "Fees",
                          })}
                          {":"}
                        </Text>
                      </Col>
                      <Col span={12}>
                        <Skeleton
                          active
                          paragraph={false}
                          loading={isLoading}
                          title={{ style: { margin: 0 } }}
                        >
                          <Text>{transaction?.Fees}</Text>
                        </Skeleton>
                      </Col>
                    </Row>
                  </List.Item>

                  <List.Item>
                    <Row
                      wrap={false}
                      align="middle"
                      gutter={[16, 0]}
                      className="w-100"
                    >
                      <Col span={12}>
                        <Text type="secondary">
                          {intl.formatMessage({
                            id: "1612",
                            defaultMessage: "Exchange fees",
                          })}
                          {":"}
                        </Text>
                      </Col>
                      <Col span={12}>
                        <Skeleton
                          active
                          paragraph={false}
                          loading={isLoading}
                          title={{ style: { margin: 0 } }}
                        >
                          <Text>{transaction?.ExchangeFees}</Text>
                        </Skeleton>
                      </Col>
                    </Row>
                  </List.Item>

                  <List.Item>
                    <Row
                      wrap={false}
                      align="middle"
                      gutter={[16, 0]}
                      className="w-100"
                    >
                      <Col span={12}>
                        <Text type="secondary">
                          {intl.formatMessage({
                            id: "121",
                            defaultMessage: "External fees",
                          })}
                          {":"}
                        </Text>
                      </Col>
                      <Col span={12}>
                        <Skeleton
                          active
                          paragraph={false}
                          loading={isLoading}
                          title={{ style: { margin: 0 } }}
                        >
                          <Text>{transaction?.ExternalFees}</Text>
                        </Skeleton>
                      </Col>
                    </Row>
                  </List.Item>

                  {transaction?.TransactionType ? (
                    <List.Item>
                      <Row
                        wrap={false}
                        align="middle"
                        gutter={[16, 0]}
                        className="w-100"
                      >
                        <Col span={12}>
                          <Text type="secondary">
                            {intl.formatMessage({
                              id: "dd.new",
                              defaultMessage: "Transaction type",
                            })}
                            {":"}
                          </Text>
                        </Col>
                        <Col span={12}>
                          <Skeleton
                            active
                            paragraph={false}
                            loading={isLoading}
                            title={{ style: { margin: 0 } }}
                          >
                            <Text>
                              {transaction?.TransactionType
                                ? transactionTypes.get(
                                    transaction.TransactionType
                                  )?.value
                                : null}
                            </Text>
                          </Skeleton>
                        </Col>
                      </Row>
                    </List.Item>
                  ) : null}
                  {transaction?.PartnerResponse &&
                    JSON.parse(
                      String(transaction?.PartnerResponse)
                    )?.data?.values?.map?.(
                      (itm: { text: string; value: string }) => (
                        <List.Item>
                          <Row
                            wrap={false}
                            align="middle"
                            gutter={[16, 0]}
                            className="w-100"
                          >
                            <Col span={12}>
                              <Text type="secondary">
                                {intl.formatMessage({
                                  id: "dd.new",
                                  defaultMessage: itm?.text,
                                })}
                                {":"}
                              </Text>
                            </Col>
                            <Col span={12}>
                              <Skeleton
                                active
                                paragraph={false}
                                loading={isLoading}
                                title={{ style: { margin: 0 } }}
                              >
                                <Text>{itm?.value}</Text>
                              </Skeleton>
                            </Col>
                          </Row>
                        </List.Item>
                      )
                    )}
                </List>
              </Col>
            ) : (
              <Col span={24}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={intl.formatMessage({
                    id: "dd.new",
                    defaultMessage: "Select transaction to see details",
                  })}
                />
              </Col>
            )}

            {size === "small" ? (
              <Col span={24}>
                <Button block type="primary" size="large" onClick={onClose}>
                  {intl.formatMessage({
                    id: "dd.new",
                    defaultMessage: "Close",
                  })}
                </Button>
              </Col>
            ) : null}
          </Row>
        </Card>
      )}
    </>
  );
};

export default TransactionsDetails;
