/**
 * Assets are ressources that may be referenced in a web page.
 * It can be a CSS file, javascript file, an image or video file, etc
 * Assets should be located in web-accessible directories or saved locally to be bundled in deployment build
 * ---
 * Import name format:
 *          - [short-name]-[app-name].[ext]
 *          - lowercase only
 *          - keep name and extension same for all apps
 *
 * Export name format:
 *          - @[app-name]-[short-name]
 */

import M2ULogo from "assets/images/m2u-logo.svg";
import YesLogo from "assets/images/yes-logo.svg";
import YesIcon from "assets/images/yes-icon.png";
import M2ULogoWhite from "assets/images/m2u-logo-white.svg";
import YesLogoWhite from "assets/images/yes-logo.svg";

// extend Map object to abstract get()
class AssetMap extends Map {
  get(name: string): string {
    const key = `@${(process.env.REACT_APP_NAME as string)
      .toLowerCase()
      .replace(/\s/, "-")}-${name}`;
    return super.get(key);
  }
}

// const ToULogo =
//   "https://celinemoneypicfiles.blob.core.windows.net/icons/logo-backoffice-color.svg";
// const ToUIcon =
//   "https://celinemoneypicfiles.blob.core.windows.net/icons/icon-backoffice-color.svg";

const assetMap = new AssetMap();

// M2U
assetMap.set("@m2u-logo", M2ULogo);
assetMap.set("@m2u-money-logo", M2ULogo);
assetMap.set("@m2u-icon", M2ULogo);
assetMap.set("@m2u-money-icon", M2ULogo);
assetMap.set("@m2u-logo-white", M2ULogoWhite);
assetMap.set("@m2u-money-logo-white", M2ULogoWhite);
assetMap.set("@m2u-brand-primary-color", "#e11b22");
assetMap.set("@m2u-money-brand-primary-color", "#e11b22");

// YES
assetMap.set("@yes-logo", YesLogo);
assetMap.set("@yes-money-logo", YesLogo);
assetMap.set("@yes-icon", YesIcon);
assetMap.set("@yes-money-icon", YesIcon);
assetMap.set("@yes-logo-white", YesLogoWhite);
assetMap.set("@yes-money-logo-white", YesLogoWhite);
assetMap.set("@yes-brand-primary-color", "#84C34F");
assetMap.set("@yes-money-brand-primary-color", "#84C34F");

export default assetMap;
